import React from "react";
import { Link } from "gatsby";

const PostCard = ({ posts }) => {
  return (
    <div className="container">
      {posts.map(({ node: post }) => (
        <div
          className="content"
          style={{ border: "1px solid #eaecee", padding: "2em 4em" }}
          key={post.id}
        >
          <p>
            <Link className="has-text-primary" to={`/blog/${post.slug}`}>
              {post.title}
            </Link>
            <span> &bull; </span>
            <small>{post.createdAt}</small>
          </p>
          <p>
            <img alt={post.image.title} src={post.image.file.url} />
            <br />
            <br />
            <Link className="button is-small" to={`/blog/${post.slug}`}>
              Keep Reading →
            </Link>
          </p>
        </div>
      ))}
    </div>
  );
};

export default PostCard;
