import React, { Component } from "react";
import { Link } from "gatsby";
import config from "../../config";
import Helmet from "react-helmet";
import PostCard from "../components/PostCard";
import Layout from "../components/Layout";


const PaginationLink = (props) => {
  if (!props.test) {
    return (
      <Link to={`/blog/${props.url}`} className="button is-rounded">
        {props.text}
      </Link>
    );
  } else {
    return (
      <span disabled className="button is-rounded">
        {props.text}
      </span>
    );
  }
};

export default class BlogPage extends Component {
  render() {
    const { pageContext } = this.props;
    const { group, index, first, last } = pageContext;
    const previousUrl = index - 1 === 1 ? "" : (index - 1).toString();
    const nextUrl = (index + 1).toString() + "/";
    const websiteSchemaOrgJSONLD = {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: config.siteUrl,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
    };
    return (
      <Layout>
        <Helmet>
          <title>Blog | Ideal Ops</title>
          {/* Schema.org tags */}
          <script type="application/ld+json">
            {JSON.stringify(websiteSchemaOrgJSONLD)}
          </script>
        </Helmet>
        <section className="hero is-primary is-bold">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="section">
                    <h1 className="title">Blog</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
        <Link to="/rss.xml" className="floatRssRight"><svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" aria-labelledby="socialTitle socialDesc" role="img" viewBox="0 0 24 24"><title id="socialTitle">Ideal Ops - Salesforce. Marketo. Blog feed.</title><desc id="socialDesc">Ideal Ops Consulting Salesforce Professional Services Blog</desc><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.29 14.646c0 .748-.607 1.354-1.354 1.354-.749 0-1.356-.606-1.356-1.354 0-.747.607-1.353 1.355-1.353.748.001 1.355.606 1.355 1.353zm-2.71-5.237v2.004c2.521.025 4.567 2.068 4.592 4.587h2.008c-.026-3.629-2.965-6.566-6.6-6.591zm0-1.404c4.407.02 7.98 3.581 7.993 7.995h2.007c-.012-5.513-4.48-9.981-10-10v2.005z"/></svg></Link>
        </div>
        <section className="section">
          <PostCard posts={group} />
          <section className="section">
            <div className="buttons is-centered">
              <PaginationLink
                test={first}
                url={previousUrl}
                text="Previous Page"
              />
              <PaginationLink test={last} url={nextUrl} text="Next Page" />
            </div>
          </section>
        </section>
      </Layout>
    );
  }
}
